<template>
  <div>
    <b-overlay :show="loading" class="row" v-if="!fulldetails">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>
              <i class="fa fa-list"></i>
              استعراض خطوط السير |
              <button
                class="btn btn-sm btn-relief-primary"
                @click="fulldetails = true"
              >
                <i class="fa fa-map"></i>
                اسعراض كامل التفاصيل
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div class="col-12 table-responsive">
              <table class="table table-hover table-bordered">
                <thead>
                  <th>العنوان</th>
                  <th>التفاصيل</th>
                  <th>عدد نقاط التوقف</th>
                  <th>خيارات</th>
                </thead>
                <tbody>
                  <tr v-for="group in groups" :key="group._id">
                    <td>
                      {{ group.title }}
                    </td>
                    <td>
                      {{ group.details }}
                    </td>
                    <td>
                      {{ group.points.length }}
                    </td>
                    <td>
                      <b-link
                        :to="'/groups/edit/' + group._id"
                        class="btn btn-relief-success btn-sm"
                      >
                        <i class="fa fa-edit"></i>
                        تعديل
                      </b-link>
                      &nbsp;
                      <button
                        @click="deleteGroup(group._id)"
                        class="btn btn-relief-danger btn-sm"
                      >
                        <i class="fa fa-trash"></i>
                        حذف
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-overlay :show="loading" class="row" v-if="fulldetails">
      <div class="col-12 col-lg-6" v-for="group in groups" :key="group._id">
        <div class="card">
          <div class="card-header">
            <div class="c">
              <h3 class="text-center c g">
                {{ group.title }}
              </h3>
              <p class="text-center c">
                {{ group.details }}
              </p>
            </div>
          </div>
          <div class="card-body">
            <div class="col-12">
              <div>
                <l-map
                  :zoom="zoom"
                  :center="[
                    group.points[0] ? group.points[0].lat : 0,
                    group.points[0] ? group.points[0].long : 0,
                  ]"
                >
                  <l-tile-layer :url="url" />
                  <l-marker
                    v-for="(point, index) in group.points"
                    :key="point.lat"
                    :lat-lng="[point.lat, point.long]"
                  >
                    <l-icon>
                      <b-img
                        v-if="index == 0 && index != group.points.length - 1"
                        :src="require('@/assets/images/start.png')"
                        height="50"
                      />
                      <b-img
                        v-if="index == group.points.length - 1 && index != 0"
                        :src="require('@/assets/images/end.png')"
                        height="50"
                      />
                    </l-icon>
                  </l-marker>
                  <l-marker
                    v-for="bus in group.busses"
                    :key="bus.lat"
                    :lat-lng="[bus.location.lat, bus.location.long]"
                  >
                    <l-icon>
                      <b-img
                        v-if="!bus.status"
                        :src="require('@/assets/images/bus_0.png')"
                        height="50"
                        :title="bus.title"
                      />
                      <b-img
                        v-if="bus.status"
                        :src="require('@/assets/images/bus_1.png')"
                        height="50"
                        :title="bus.title"
                      />
                    </l-icon>
                  </l-marker>
                </l-map>
              </div>
            </div>
            <div class="col-12">
              <br />
              <b-link
                :to="'/groups/edit/' + group._id"
                class="btn btn-lg btn-relief-success btn-block"
              >
                تعديل خط السير
                <i class="fa fa-arrow-left"></i>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BLink,
  BDropdown,
  BDropdownItem,
  BImg,
  BFormGroup,
} from "bootstrap-vue";
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    BLink,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
  },
  data() {
    return {
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      polyline: {
        latlngs: [],
        latlngs2: [],
        color: "green",
        color2: "red",
      },
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      //////
      fulldetails: false,
      loading: true,
      groups: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    checkPer(this, "groups", "view");
    var g = this;
    g.get();
  },
  methods: {
    get() {
      var g = this;
      g.loading = true;
      setTimeout(() => {
        $.post(api + "/admin/groups/list-full", {
          jwt: g.user.jwt,
        }).then(function (r) {
          g.groups = JSON.parse(r).response;
          g.loading = false;
        });
      }, 1);
    },
    deleteGroup(id) {
      var g = this;
      if (
        confirm(
          "متأكد من حذف خط السير نهائياً؟\nمن الأفضل تركه وتغيير اسمه فقط لعدم فقدان اي تقارير سابقة."
        )
      ) {
        $.post(api + "/admin/groups/delete", {
          jwt: g.user.jwt,
          id: id,
        }).then(function (r) {
          alert("تم الحذف");
          g.get();
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>